
.course-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    .news-top {
        padding: 20px;
        border-bottom: 1px solid #EAEAEA;
    }
    .news-box {
        height: 1%;
        flex: 1;
        overflow: hidden;
        .el-form {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;
            .el-form-bottom {
                border-top: 1px solid #EAEAEA;
                padding: 20px;
                margin-bottom: 0;
            }
            .el-form-item-cover {
                ::v-deep .el-form-item__content {
                    display: flex;
                    align-items: flex-end;
                }
            }
        }
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                padding: 20px 20px 0;
            }
        }
    }
}
.news-prompt {
    color: #F56C6C;
    margin-left: 10px;
}
.news-uploader {
    display: inline-block;
    ::v-deep .el-upload {
        /*background: #fbfdff;*/
        width: 328px;
        height: 200px;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            border-color: #409EFF;
        }
        .news-uploader-icon {
            font-size: 28px;
            color: #8c939d;
        }
        .news-cover {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
